import React, { useState, useEffect } from "react"
import styles from "./index.module.scss"
import localidades from "./proviciasylocalidades.json"
import provincias from "./provincias.json"
import { validateEmail, formatDate } from "../../../../utils/functions"
import { Checkbox } from "antd"
import { navigate } from "gatsby"
import Cookies from "js-cookie"

const ModalForm = ({ setShowModal, selectedPlayer }) => {
  const [data, setData] = useState({
    name: "",
    dateofbirth: "",
    email: "",
    city: "",
    state: "",
    phone: "",
    club: "",
    dni: "",
    preferenceQuestion: "",
  })
  const [validateError, setValidateError] = useState("")
  const [checked, setChecked] = useState(false)
  const [cities, setCities] = useState([])

  useEffect(() => {
    const localidadesArr = Object.entries(localidades)
    if (data.state) {
      const filter = localidadesArr.filter(([key, value]) => {
        return key === data.state
      })
      const justStrings = Object.fromEntries(filter)
      setCities(justStrings[data.state])
    }
  }, [data.state])

  const handleInputChange = e => {
    const { name, value } = e.target
    setData(data => ({ ...data, [name]: value }))
  }

  const MessageValidate = () => {
    switch (validateError) {
      case "email":
        return <h1 className={styles.error}>EL EMAIL ES INVALIDO</h1>
      case "empty":
        return <h1 className={styles.error}>DEBE COMPLETAR TODOS LOS CAMPOS</h1>
      case "duplicated":
        return (
          <h1 className={styles.error}>El email ingresado ya está en uso</h1>
        )
      case "terms":
        return (
          <h1 className={styles.error}>
            DEBE ACEPTAR LOS TERMINOS Y CONDICIONES
          </h1>
        )
      case "success":
        return null
      default:
        return null
    }
  }

  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  // const api = "http://localhost:8080/api"

  const handleSubmit = e => {
    e.preventDefault()

    if (
      data.name &&
      data.state &&
      data.dateofbirth &&
      data.city &&
      data.club &&
      data.phone &&
      data.dni &&
      data.preferenceQuestion
    ) {
      if (validateEmail(data)) {
        console.log("validadoos")
        if (checked) {
          // ACA SI TODO ESTA BIEN
          setValidateError("success")
          console.log("form!", data)
          let td = Cookies.get("_td")
          try {
            fetch(`${api}/balondepotrero/votes/playeofthemonth`, {
              method: "POST",
              body: JSON.stringify({
                player: selectedPlayer,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            })
          } catch (error) {
            console.log(error)
          }
          fetch(`${api}/balondepotrero/newsletter`, {
            method: "POST",
            body: JSON.stringify({ ...data, td, playerId: selectedPlayer }),
            // credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }).then(response => {
            if (response.ok) {
              window.location.href = `${window.origin}/balondepotrero/success`
            } else {
              setValidateError("duplicated")
              response.text().then(text => {
                console.error("Error guardando los datos. \n", text)
              })
            }
          })
        } else {
          setValidateError("terms")
        }
      } else {
        setValidateError("email")
      }
    } else {
      setValidateError("empty")
    }
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <p
          onClick={() => {
            setShowModal(false)
          }}
        >
          x
        </p>
        <div className={styles.subtitle}>Registrate y participá</div>
        <div className={styles.title}>¡Sumate a balón de potrero!</div>
        <form action="" className={styles.mainTextContainer}>
          <div className={styles.formRow}>
            <div className={styles.field}>
              <p>Nombre y apellido</p>
              <input
                type="text"
                name="name"
                value={data.name}
                placeholder="Nombre y apellido"
                onChange={handleInputChange}
              />
            </div>

            <div className={styles.field}>
              <p>Mail</p>
              <input
                type="text"
                name="email"
                value={data.email}
                placeholder="Mail"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.field}>
              <p>Fecha de nacimiento</p>
              <input
                type="text"
                value={data.dateofbirth}
                name="dateofbirth"
                placeholder="DD/MM/AA"
                onChange={({ target }) => {
                  const onlyNums = target.value.replace(/[^0-9]/g, "")
                  const date = formatDate(onlyNums)
                  setData({ ...data, dateofbirth: date })
                }}
              />
            </div>

            <div className={styles.field}>
              <p>Provincia</p>
              <select
                type="text"
                name="state"
                value={data.state}
                onChange={handleInputChange}
              >
                <option selected hidden>
                  Provincia
                </option>
                {provincias.map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.field}>
              <p>Localidad</p>
              {/* <input
                list="cities"
                type="city"
                value={data.city}
                // className={styles.simpleInput}
                placeholder="Localidad"
                onChange={handleInputChange}
                name="city"
              />
              <datalist id="cities">
                {cities?.map(city => {
                  return <option key={city} value={city} />
                })}
              </datalist> */}

              <select
                type="text"
                name="city"
                value={data.city}
                onChange={handleInputChange}
              >
                <option selected hidden>
                  Localidad
                </option>
                {cities.sort().map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option}
                    </option>
                  )
                })}
              </select>
            </div>

            <div className={styles.field}>
              <p>DNI</p>
              <input
                type="number"
                name="dni"
                placeholder="DNI"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.field}>
              <p>Teléfono</p>
              <input
                type="number"
                name="phone"
                placeholder="Teléfono"
                onChange={handleInputChange}
              />
            </div>

            <div className={styles.field}>
              <p>Club</p>
              <input
                type="text"
                name="club"
                placeholder="Club"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.fullwidth}>
              <p>¿En qué ocasiones acostumbras consumir cerveza?</p>
              <select
                type="text"
                name="preferenceQuestion"
                onChange={handleInputChange}
              >
                <option
                  selected
                  hidden
                  style={{ color: "lightgrey !important" }}
                >
                  Elegir opción
                </option>
                <option value="Meals">
                  Cocinando/ Almorzando o Cenando solo o acompañado
                </option>
                <option value="Relax">
                  Cuando me quiero relajar, viendo TV, en una plaza, usando RRSS
                  o tomando sol.
                </option>
                <option value="Social">
                  Con amigxs/ compañeros socializando en casa o en un bar.
                </option>
                <option value="Party">En una fiesta, preboliche, etc.</option>
                <option value="Living">
                  Trasladandome a algun lugar, mientras trabajo o hago mandados.
                </option>
                <option value="No">No tomo cerveza</option>
              </select>
            </div>
          </div>

          <div className={styles.checkboxrow}>
            <Checkbox
              className={styles.checkbox}
              type="checkbox"
              checked={checked}
              onChange={({ target }) => setChecked(target.checked)}
            />
            <p className={styles.checkboxtext}>
              He leído y acepto{" "}
              <a
                className={styles.linksa}
                href="https://www.quilmes.com.ar/politicadeprivacidad.pdf"
                target="_blank"
              >
                Bases y Condiciones
              </a>
              ,
              <a
                className={styles.linksa}
                href="https://www.quilmes.com.ar/politicadeprivacidad.pdf"
                target="_blank"
              >
                {" "}
                Políticas de Privacidad
              </a>
              ,
              <a
                className={styles.linksa}
                href="https://www.quilmes.com.ar/terminosycondiciones.pdf"
                target="_blank"
              >
                {" "}
                Términos y Condiciones
              </a>{" "}
              y activaciones de marketing
            </p>
          </div>

          <button type="submit" onClick={handleSubmit}>
            {" "}
            ENVIAR{" "}
          </button>
          <MessageValidate />
        </form>
      </div>
    </div>
  )
}

export default ModalForm
