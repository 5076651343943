import React, { useState, useEffect } from "react"
import styles from "./index.module.scss"

import Scroll from "react-scroll"

const Element = Scroll.Element

const Submit = ({
  enabledSubmitButton,
  selectedPlayer,
  setShowModal,
}) => {
  
  const [voteSubmitted, setVoteSubmitted] = useState(false)

  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  // const api = "http://localhost:8080/api"

  const handleSubmit = async () => {
    if (!voteSubmitted && enabledSubmitButton) {
      setShowModal(true)
      // try {
      //   setVoteSubmitted(true)
      //   let response = await fetch(`${api}/balondepotrero/votes/playeofthemonth`, {
      //     method: "POST",
      //     body: JSON.stringify({
      //       player: selectedPlayer,
      //     }),
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   if (response.ok) {
      //     setShowModal(true)
      //   } else {
      //     console.error("Error guardando los datos. \n", response)
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
    }else{
      console.log("Not Allowed")
    }
  }

  return (
    <Element name={"submit"}>
      <div className={styles.buttonContainer}>
        <button
          className={!enabledSubmitButton && styles.disabledButton}
          onClick={handleSubmit}
        >
          Enviar voto
        </button>
      </div>
    </Element>
  )
}

export default Submit
