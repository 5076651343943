import React, { useEffect } from "react"
import styles from "./index.module.scss"
import clockDot from "../../../images/balondepotrero/clock-dot.svg"

const Countdown = ({ totalSeconds, setTotalSeconds, pollView, activePoll }) => {
  useEffect(() => {
    if (totalSeconds > 0) {
      setTimeout(function () {
        setTotalSeconds(totalSeconds - 1)
      }, 1000)
    }
  }, [totalSeconds])

  const getDays = (totalSeconds) => {
    return ("0" + Math.floor(totalSeconds / 86400)).slice(-2)
  }

  const getHours = (totalSeconds) => {
    return ("0" + Math.floor((totalSeconds % 86400) / 3600)).slice(-2)
  }

  const getMinutes = (totalSeconds) => {
    return ("0" + Math.floor((totalSeconds % 3600) / 60)).slice(-2)
  }

  const getSeconds = (totalSeconds) => {
    return ("0" + (totalSeconds % 60)).slice(-2)
  }

  return (
    <div
      className={`${styles.container} ${!pollView && styles.smallerContainer}`}
    >
      {pollView && (
        <>
          <div className={styles.title}>
            {/* Elegí los jugadores del mes */}
            Elegí al Balón de Potrero de este mes.
          </div>
          {/* <div className={styles.subtitle}>
            Seleccioná un jugador por puesto
          </div> */}
        </>
      )}
      <div className={styles.clockContainer}>
        <div className={styles.clockColumn}>
          <div className={styles.clockAmount}>{getDays(totalSeconds)}</div>
          <div className={styles.clockUnit}>días</div>
        </div>
        <div className={styles.clockDotContainer}>
          <img className={styles.clockDot} src={clockDot} alt="Punto" />
        </div>
        <div className={styles.clockColumn}>
          <div className={styles.clockAmount}>{getHours(totalSeconds)}</div>
          <div className={styles.clockUnit}>horas</div>
        </div>
        <div className={styles.clockDotContainer}>
          <img className={styles.clockDot} src={clockDot} alt="Punto" />
        </div>
        <div className={styles.clockColumn}>
          <div className={styles.clockAmount}>{getMinutes(totalSeconds)}</div>
          <div className={styles.clockUnit}>mins</div>
        </div>
        <div className={styles.clockDotContainer}>
          <img className={styles.clockDot} src={clockDot} alt="Punto" />
        </div>
        <div className={styles.clockColumn}>
          <div className={styles.clockAmount}>{getSeconds(totalSeconds)}</div>
          <div className={styles.clockUnit}>segs</div>
        </div>
      </div>
      <div className={styles.deadline}>
        {pollView
          ? "Para que termine la votación"
          : activePoll
          ? "Para que termine la votación actual"
          : "¡Hasta la próxima votación!"}
      </div>
    </div>
  )
}

export default Countdown
