import React, { useState, useEffect } from "react";
import Scroll from "react-scroll";
import Slider from "react-slick";
import styles from "./index.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider-fix.css";

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const Players = ({
  players,
  position,
  selectedPlayer,
  setSelectedPlayer,
  pollView,
  month,
  nextSection,
  setMes
}) => {
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    // lazyLoad: true,
    // speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // variableWidth: true,
    rows: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    return month ? setMes(month) : null;
  }, [month]);

  return (
    <>
      <Element name={pollView ? position : month}></Element>
      <div
        className={`${styles.container} ${
          position === "delantero" && styles.evenNumberColor
        }
        ${position === "delantero" && styles.evenNumberColor}`}
      >
         {pollView ? (
          <div className={`${styles.title} ${styles.strongTitle}`}>
            Mejor jugador de potrero del mes
          </div>
        ) : (
          <div className={styles.title}>
            {/* Mejor jugador del mes */}
          </div>
        )} 
        <div className={styles.playersContainer}>
          <div className="slick-fix-container">
            <Slider {...settings}>
              {players.map((player, index) => {
                // console.log("player", player)
                return (
                  <div
                    className={styles.singlePlayerContainer}
                    onClick={() => {
                      if (pollView) {
                        setSelectedPlayer(player.idbpmonthplayers);
                        pollView &&
                          scroller.scrollTo(nextSection, {
                            duration: 1000,
                            delay: 100,
                            smooth: true,
                          });
                      }
                    }}
                    key={index}
                  >
                    <div className={styles.singleImageAndBorderContainer}>
                      <div
                        className={`${styles.playerImageContainer} ${
                          selectedPlayer === player.idbpmonthplayers &&
                          styles.playerImageContainerSelected
                        }`}
                      >
                        <img
                          className={styles.playerImage}
                          src={player.image}
                          alt="Jugador"
                        />
                      </div>
                    </div>

                    <div className={styles.playerName}>{player.name}</div>
                    <div className={styles.matchDay}>
                      {player.month}
                    {/* {pollView
                      ? "Fecha " + player.matchday
                      : "Mejor " + player.position} */}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Players;
